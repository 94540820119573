import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, navigate } from 'gatsby'

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Card from "components/Card/Card.jsx";
import carouselStyle from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.jsx";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

import insightsBG from "assets/img/insights-content-bg.png";
import backBtn from "./backBtn.svg";
import { CssBaseline, Typography } from "@material-ui/core";

// import NewsLetterSection from "../LandingPage/Sections/NewsLetterSection";
// import FooterSection from "../LandingPage/Sections/FooterSection";
// import brandsImg from "assets/img/our-brands.png";
// import ProductCarousel from "./ProductCarousel";
// import CategoriesSection from "./CategoriesSection";
import FooterSection from "../../pages/LandingPage/Sections/FooterSection";
// import NewsLetterSection from "../../pages/LandingPage/Sections/NewsLetterSection";

const dashboardRoutes = [];

class InsightsContent extends React.Component {
    render() {
        const { classes, prod, contentBG, hImage, titleText, bodyText, numberText, ...rest } = this.props;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };
        return (
            <div>
                <Header
                    color="transparent"
                    routes={dashboardRoutes}
                    brand="AL MIDIAFF INTERNATIONAL SUPPLIES"
                    // brand="AL MIDIAFF INTERNATIONAL Co. LLC"
                    // brand="AL MIDIAFF"
                    rightLinks={<HeaderLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 400,
                        color: "white"
                    }}
                    {...rest}
                />
                <CssBaseline />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div style={{ background: "url(" + insightsBG + ")", backgroundPosition: "left bottom", backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
                    {/* <div style={{ background: "#00AFD9", backgroundPosition: "left bottom", backgroundRepeat: "no-repeat", backgroundSize: "100%" }}> */}
                        <div style={{ height: 160 }}></div>
                        <div style={{ textAlign: "left" }}>
                            <Link to="/insights/">
                                <img src={backBtn} alt="" width="120" />
                            </Link>
                        </div>
                        <div className={classes.section} style={{ padding: "0 5%" }}>

                            <div className={classes.container}>
                                <GridContainer aria-label="breadcrumb">
                                    <a href="/">Home /</a>
                                &nbsp;
                                <a href="/insights/">Insights</a>
                                </GridContainer>
                            </div>
                            <div style={{ height: 20 }}></div>
                            <h1>Insights</h1>
                            <div style={{ height: 20 }}></div>
                            {/* <div style={{ background: "url(" + contentBG + ")", backgroundSize: "100%", backgroundRepeat: "no-repeat" }}> */}
                            <div style={{ background: contentBG }}>
                                <div className={classes.section} style={{ padding: "4%", color:"#fff" }}>
                                    <GridContainer>
                                        <GridItem xs={10} sm={5} md={5} style={{ textAlign: "center", color:"#fff" }}>
                                            <h2>{titleText}</h2>
                                        </GridItem>
                                        <GridItem xs={12} sm={5} md={5} style={{ textAlign: "center" }}>
                                            <div style={{ height: 40 }}></div>
                                            <img src={hImage} alt="" style={{ width: "80%" }} />
                                        </GridItem>
                                        <GridItem xs={2} sm={2} md={2} style={{ textAlign: "center", color:"#fff" }}>
                                            <h1><u>{numberText}</u></h1>
                                        </GridItem>
                                    </GridContainer>
                                    <div style={{ height: 20 }}></div>
                                    <GridContainer>
                                        <GridItem xs={10} sm={10} md={10} style={{ textAlign: "left", color:"#fff" }}>
                                            <p>
                                                {bodyText}
                                            </p>
                                        </GridItem>
                                        <GridItem xs={2} sm={2} md={2} style={{ textAlign: "center" }}></GridItem>
                                    </GridContainer>
                                </div>
                                <div style={{ height: 80 }}></div>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{ background: "#6C648B", width: "100%" }}>
                        <div className={classes.container} >
                            <NewsLetterSection />
                        </div>
                    </div> */}

                    <div style={{ background: "#B6A19E", minHeight: "2em" }}></div>
                    <FooterSection />

                </div>
                <Footer />
            </div>
        );
    }
}

export default withStyles(landingPageStyle, productStyle, carouselStyle)(InsightsContent);
